<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar"/>

    <breadcrumb v-if="!topNav" id="breadcrumb-container" class="breadcrumb-container"/>

    <top-nav v-if="topNav" id="topmenu-container" class="topmenu-container"/>
    
    <div class="dev-class" v-if="isDev">当前为-测试环境</div>

    <div style="flex:1;"></div>

    <!-- <el-button class="tag-head" size="mini" plain @click="goRecordTag()">个人标签项目</el-button> -->

    <div class="right-menu">

      <template v-if="device!=='mobile'">
        <el-tooltip content="查看通知" effect="dark" placement="bottom">
          <notice class="right-menu-item hover-effect"/>
        </el-tooltip>

        <search id="header-search" class="right-menu-item"/>

        <screenfull id="screenfull" class="right-menu-item hover-effect"/>

        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect"/>
        </el-tooltip>

      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <div class="name_box">
            <div class="nickName">{{ name }}</div>
            <span class="userName">({{ userCount }})</span>
          </div>
          <i class="el-icon-caret-bottom"/>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-tag v-if="taskWarning" class="task-warning" type="danger" @click="goToTask()">
      你有{{ taskWarning }}条超时事项提醒，请及时处理！
    </el-tag>

    <RecordTag ref="recordTag" />
  </div>
</template>

<script>
import {getToken} from "@/utils/auth";
import {mapGetters} from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Notice from "@/components/HeaderNotice";
import Search from "@/components/HeaderSearch";
import RuoYiGit from "@/components/RuoYi/Git";
import RuoYiDoc from "@/components/RuoYi/Doc";
import Cookies from "js-cookie";
import {countUserTaskWarning} from "@/api/project/taskAlert";
import {getSysUserNotice} from "@/api/system/notice";
import RecordTag from "@/components/RecordTag";
export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc,
    Notice,
    RecordTag
  },
  dicts: ["bpm_record_task_notice_type", "bpm_record_task_notice_msg_type"],
  data() {
    return {
      userId: Cookies.get("username"),
      websocket: null,
      websocketOpenStatus: false,
      retryTimer: null,
      retryTimes: 0,
      isDev: false,
      taskWarning: null,
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "name", "userCount"]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
  },
  created() {
    if (process.env.VUE_APP_NODE_ENV !== "production") {
      this.isDev = true
    }

    if (process.env.VUE_APP_NODE_ENV !== "development") {
      this.connectWebSocket();
    }

    // 初始化预警通知总数
    countUserTaskWarning().then(res => {
      if (res.code == 200) {
        this.taskWarning = res.data
      }
    })

    // 初始化通知总数
    getSysUserNotice({size: 0, status: 0, current: 1}).then(res => {
      if (res.code == 200) {
        this.$store.dispatch('settings/setInfoNoticeNum', res.data.total)
      }
    })

  },
  beforeDestroy() {
    if (this.websocket) {
      this.websocket.close();
    }
  },
  methods: {
    // 个人项目标签
    goRecordTag() {
      this.$refs.recordTag.openShowDialog();
    },
    // 预警待办任务跳转
    goToTask() {
      let routeList = this.$store.state.permission.sidebarRouters
      let isTo = routeList.some(item => item.path === '/business')
      let url = this.$router.resolve({
        path: isTo ? "/business/todoTask" : "/workbenchDeliver/todoTask",
        query: {
          warning: true,
        },
      }).href;
      window.open(url, "_blank");
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            location.href = "/index";
          });
        })
        .catch(() => {
        });
    },
    // 连接WebSocket
    connectWebSocket() {
      const that = this

      let wsUrl = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
      if (process.env.VUE_APP_NODE_ENV === "development") {
        wsUrl += '192.168.60.220:8080/system/api/websocket/notice/'
      } else if (process.env.VUE_APP_NODE_ENV === "production") {
        wsUrl += window.location.host + '/socket/system/api/websocket/notice/'
      } else {
        wsUrl += window.location.host + '/socket/system/api/websocket/notice/'
      }

      if (!getToken()) {
        return
      }

      this.websocket = new WebSocket(
        wsUrl + this.$store.state.user.id + '?Authorization=' + getToken()
      );

      this.websocket.onopen = () => {
        console.log("WebSocket connected");
        that.websocketOpenStatus = true;
        that.retryTimes = 0;
        if (that.retryTimer) {
          clearInterval(that.retryTimer);
          that.retryTimer = null;
        }
      };

      this.websocket.onmessage = (event) => {
        console.log("event.data", event.data);
        let Data = JSON.parse(event.data);

        if (Data && Data.noticeType == -1) {
          // 设置消息总数
          this.$store.dispatch('settings/setInfoNoticeNum', Data.noticeCount)

        } else if (Data) {
          // 设置消息总数
          let infoNoticeNum = (this.$store.state.settings.infoNoticeNum || 0) + 1
          this.$store.dispatch('settings/setInfoNoticeNum', infoNoticeNum)
          console.log("infoNoticeNum", infoNoticeNum);

          let tle = this.dict.type["bpm_record_task_notice_type"].find((item) => {
            return item.value == Data.noticeType;
          })?.label || "系统通知";

          let sendContent = `<div style="line-height:30px;    word-break: break-all;"><div>${Data.noticeTitle}<a style="display:inline-block;color:#3278fe" target="_blank" href="${Data.noticeUrl}">&nbsp;&nbsp;&nbsp;&nbsp;点击查看项目</a></div><div style="display: flex; justify-content: space-between; align-items: center;margin-top:5px"><div style="background: #f2f2f2; padding: 1px; border-radius: 5px; font-size: 13px; width: 80px; text-align: center;" type="info">${Data.createBy}</div>  <div>${Data.createTime} </div> </div> </div>`;

          this.$notify({
            customClass: "custom-notify",
            duration: 4500,
            type: "warning",
            title: tle,
            dangerouslyUseHTMLString: true,
            message: sendContent,
          });

        }

      };

      this.websocket.onclose = () => {
        // console.log(`WebSocket closed ${that.retryTimes}`);
        that.websocketOpenStatus = false;

        if (that.retryTimer) {
          // console.log(`WebSocket closed retryTimer ${that.retryTimes}`);
          return
        }

        that.retryTimer = setInterval(() => {
          if (that.websocketOpenStatus) {
            return;
          }

          that.retryTimes += 1;
          // console.log(`WebSocket reconnect ${that.retryTimes}`)

          that.connectWebSocket()
        }, 3000)

      };

      this.websocket.onerror = (error) => {
        console.error("WebSocket error: " + error);
      };

    }
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  align-items: center;
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    // float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    // float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .dev-class {
    color: red;
    width: 140px;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    // margin: 0 auto;
  }

  .task-warning {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 32px;
    font-size: 14px;
    line-height: 32px;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        display: flex;

        .name_box {
          display: inline-block;
          transform: translateY(-10px);
          margin-left: 5px;
          text-align: center;

          .nickName {
            height: 15px;
            font-size: 14px;
          }

          .userName {
            height: 20px;
            font-size: 12px;
            color: #b3a9a9;
          }
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
