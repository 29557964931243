<template>
  <div>
    <!-- 添加或修改个人户预约对话框 -->
    <el-dialog title="选择预约时间" :visible.sync="openTime" width="1200px" append-to-body>
      <div class="date-box">
        <div class="calendar_box">
          <div class="calendar_top">
            <div class="ct_years">
              <span class="year_time">{{ year }}年 {{ month }}月</span>
            </div>
            <div class="ct_years">
              <img class="left_triangle nosee" @click="changeMonth(0)" src="https://oss.71go.com/noa0906/noa34.png"
                  alt="" />
              <img class="right_triangle nosee" @click="changeMonth(1)" src="https://oss.71go.com/noa0906/noa34.png"
                  alt="" />
            </div>
          </div>
          <div class="calendar_center">
            <div class="cct_list">
              <div class="cct_item">日</div>
              <div class="cct_item">一</div>
              <div class="cct_item">二</div>
              <div class="cct_item">三</div>
              <div class="cct_item">四</div>
              <div class="cct_item">五</div>
              <div class="cct_item">六</div>
            </div>
            <div class="ccb_list">
              <div :class="[
                'ccb_item',
                !day.belong ? 'no_belong' : '',
                day.list ? 'reducible' : '',
                Number(day.day) < Number(dateToday) ? 'mday' : '',
                day.appointmentTotal <= 0 ? 'nreducible' : '',
                `${year}${9 >= month ? '0' + month : month}${9 >= timeToday ? '0' + timeToday : timeToday}` == day.day
                  ? 'active'
                  : '',
              ]" v-for="(day, index) in dayArr" :key="index" @click="changeDateItem(day)">
                <div class="new_c" v-if="day.belong">{{ day.key }}</div>
                <div class="dot dots" v-if="day.appointmentTotal <= 0 && day.belong">{{day.statusName}}</div>
                <div class="dot" v-else>
                  {{ day.list && day.list.length && day.belong > 0 ? '余' + day.appointmentTotal : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="time-box">
          <el-table 
            :data="dayDateList"
            border 
            width="400"
            ref="dayDateList"
            class="single-select-table"
            highlight-current-row
            @current-change="handleSelectionTimeChange">
            <el-table-column label="时间段" align="center">
              <template slot-scope="scope">
                {{ JSON.parse(scope.row.timeStr.split('-')[1].split(':')[0]) <= 12 ? '上午' : '下午' }}
              </template>
            </el-table-column>
            <el-table-column label="时间" align="center" prop="timeStr" width="120"/>
            <el-table-column label="可预约数量" align="center">
              <template slot-scope="scope">
                <span style="color:blue;">{{ scope.row.appointmentNum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="剩余预约数量" align="center"  width="120">
              <template slot-scope="scope">
                <span style="color:red;">{{ scope.row.appointmentNum - scope.row.alreadyNum }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormTime">确 定</el-button>
        <el-button @click="cancelTime">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dateForm: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dateForm: {
      handler(val) {
        if (val.length) {
          console.log(val,'PPPPPPPPPPP');
          this.getOpenTime(this.currDate)
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      openTime:false,
      year: null,
      month: null,
      dayArr:[],
      dayDateList:[],
      dateToday:'',
      isFirst:true,
      multipleTimeSelection:null,
      chooseDay: null,
      currDate:null,
      timeToday: null,
    }
  },
  methods: {
    // 日历时间切换
    async changeMonth(flag) {
      if (this.rqFlag) {
        return;
      }
      this.rqFlag = true;
      switch (flag) {
        case 0:
          if (this.month == 1) {
            this.month = 12;
            this.year = parseInt(this.year) - 1;
          } else {
            this.month = this.month - 1;
          }

          break;
        case 1:
          if (this.month == 12) {
            this.month = 1;
            this.year = parseInt(this.year) + 1;
          } else {
            this.month = this.month + 1;
          }
          break;
      }
      await this.$emit("getList", `${this.year}-${this.month}-01 00:00:00`);
      this.currDate = `${this.year}-${this.month}-01`;
      // this.getOpenTime(`${this.year}-${this.month}-01`);
      this.rqFlag = false;
    },
    getOpenTime(row) {
      this.multipleTimeSelection = null;
      this.openTime = true;
      this.title='选择预约时间';
      let perDate = row ? row : '';
      let showTime = '';
      let showDay = ''
      if(perDate){
        let time = JSON.parse(JSON.stringify(perDate))
        showTime  = time.substring(0,8)+'01';
        showDay = time.substring(8,10);
      }
      this.chooseId = row ? row.bankId ? row.bankId : this.chooseId : this.chooseId;
      let date =  perDate ? new Date(perDate) : new Date(),
        month = date.getMonth(),
        year = date.getFullYear(),
        timeToday = date.getDate() ;
      this.timeToday = showDay || timeToday;
      this.year = year;
      this.month = month + 1;
      console.log(`${this.year}-${this.month}-${this.timeToday}`);
      this.getEveryDay(date, month, year);
    },
    async getEveryDay(date, month, year) {
      this.rqFlag = true;
      //设置月份
      let date1 = new Date()
      date1.setMonth(month + 1);
      //设置一个月的某一天-这里设置为零则取到的日期中的天就会是当月的最后一天（比如，二月份就是28或29，其他月份就是30或31），方便下边的循环
      date1.setDate(0);
      //获取月份的某一天
      let day = date1.getDate();
      let data = [];
      for (let i = 1; i <= day; i++) {
        date.setDate(i);

        data.push({
          day: `${year}${month + 1 > 9 ? month + 1 : "0" + (month + 1)}${i > 9 ? i : "0" + i
          }`,
          month: month + 1,
          key: i,
          table_one: i,
          //选中月份的每一天和当天是星期几
          table_two: [7, 1, 2, 3, 4, 5, 6][date.getDay()],
          belong: true,
        });
      }
      let filterGoods = [[], [], [], [], [], [], []];
      let filterIndex = 0;
      let findex = 0
      data.forEach((item, index) => {
        if (index == 0 && item.table_two !== 7) {
          let copyarr = [];
          for (var dayindex = 0; dayindex <= 6; dayindex++) {
            if (dayindex >= item.table_two) {
              copyarr.push({
                day: this.dealTime(dayindex, item.day,'add',findex),
                table_two: dayindex,
                belong: true,
                month: item.month,
                key: parseInt(
                  this.dealTime(dayindex, item.day,'add',findex).slice(
                    this.dealTime(dayindex, item.day).length - 2,
                    this.dealTime(dayindex, item.day).length
                  )
                ),
              });
              findex++
            } else {
              copyarr.push({
                day: this.dealTime(dayindex, item.day),
                table_two: dayindex,
                belong: false,
                month: item.month != 1 ? item.month - 1 : 12,
                key: this.dealTime(dayindex, item.day).slice(
                  this.dealTime(dayindex, item.day).length - 2,
                  this.dealTime(dayindex, item.day).length
                ),
              });
            }
          }

          filterGoods[filterIndex] = copyarr;
        } else if (index == data.length - 1 && item.table_two !== 7) {
          // let copyarr = [];
          // for (var dayindex = (item.table_two + 1); dayindex <= 6; dayindex++) {
          //   copyarr.push({
          //     day: this.dealTime(dayindex, item.day),
          //     table_two: dayindex,
          //     belong: false,
          //     month: item.month != 12 ? item.month + 1 : 1,
          //     key: this.dealTime(dayindex, item.day).slice(
          //       this.dealTime(dayindex, item.day).length - 2,
          //       this.dealTime(dayindex, item.day).length
          //     ),
          //   });
          // }
          // console.log(copyarr,'copyarrcopyarrcopyarr',filterGoods.length);
          // filterGoods[filterGoods.length - 1] = filterGoods[filterGoods.length - 1].concat(copyarr);
        }
        if (item.table_two == 7) {
          filterIndex++;
        }

        if (filterGoods[filterIndex].length < 7) {
          typeof filterGoods[filterIndex] == "undefined"
            ? ""
            : filterGoods[filterIndex].push(item);
        }
      });
      console.log(data,'111',filterGoods, filterGoods.flat());
      this.dayArr = filterGoods.flat();
      this.dateForm.forEach(item => {
        this.dayArr?.forEach(item1 => {
          if(item.dateStr == item1.day) {
            this.$set(item1,'list',item.autoTimeSlots)
            this.$set(item1,'statusName',item.statusName)
            this.$set(item1,'appointmentTotal',item.appointmentTotal)
          }
        })
      })
      filterGoods.forEach((fgitem, index) => {
        fgitem.forEach((fm) => {
          if (
            fm.day ==
            `${this.year}${9 >= this.month ? "0" + this.month : this.month}${9 >= this.timeToday ? "0" + this.timeToday : this.timeToday
            }`
          ) {
            let that = this;
            setTimeout(function () {
              that.changeDateItem(fm,true);
              that.rqFlag = false;
            }, 500);
          }
          // fm.list = oaCalendarArr[0][`${fm.day}`];
        });
      });
       this.rqFlag = false;
      
      console.log(this.dayArr,'this.dayArrthis.dayArr');
    },
    changeDateItem(day,i = false) {
      console.log(day,'daydaydayday');
      this.dayDateList = this.dayArr.find(item => item.day === day.day).list
      this.handleSelectionTimeChange()
      console.log(this.dayDateList,'dayDateList');
      if(this.isFirst) {
        this.dateToday = day.day
        console.log(this.dateToday,'this.dateToday');
        this.isFirst = false
      }
      if((Number(day.day) < Number(this.dateToday)) && !i) {
        this.$modal.msgError("请选择当天之后的日期！");
        return false
      }
      if((day.table_two == 6 || day.table_two == 7) && !i) {
        this.$modal.msgError("请选择工作日！");
        return false
      }
      if (!day.belong) {
        return false;
      }
      if (day.statusName=='不可约') {
        return false;
      }
      this.chooseDay = day.day;
      this.timeToday = parseInt(day.day.substring(6, 8));
      this.month = parseInt(day.day.substring(4, 6));
      this.year = day.day.substring(0, 4);
    },
    // 格式化日期
    formatDate(str) {
      const year = str.slice(0, 4);
      const month = str.slice(4, 6);
      const day = str.slice(6, 8);
      return `${year}-${month}-${day}`;
    },
    dealTime(dayNum, dat,type = '',i) {
      if(type == 'add'){
        let fday = dat.substring(6, 8);
        let day = Number(fday)+i;
        return dat.substring(0,6)+''+(day > 9 ? day : ('0' + day));
      }else{
        return dat;
      }
    },
    // 表格勾选
    handleSelectionTimeChange(row) {
      this.multipleTimeSelection = row || null;
      console.log(this.multipleTimeSelection,'this.multipleTimeSelection');
    },
    submitFormTime() {
      if(!this.multipleTimeSelection) {
        this.$modal.msgError("请先选择预约时间段再重新提交！");
        return false
      }
      let date = this.formatDateString(this.chooseDay) + ' ' + this.multipleTimeSelection.timeStr
      this.openTime = false
      this.$emit('subDate',date)
      this.reset();
    },
    formatDateString(dateString) {
      // 检查输入的日期字符串是否符合格式要求
      if (dateString.length !== 8) {
          return "Invalid date format";
      }
      
      // 使用substr方法提取年、月、日部分并添加连字符
      const year = dateString.substr(0, 4);
      const month = dateString.substr(4, 2);
      const day = dateString.substr(6, 2);
      
      // 返回格式化后的日期字符串
      return `${year}-${month}-${day}`;
    },
    cancelTime() {
      this.openTime = false;
      this.reset();
    },
    reset() {
      this.openTime = false;
      this.year =  null;
      this.month =  null;
      this.dayArr = [];
      this.dayDateList = [];
      this.dateToday = '';
      this.isFirst = true;
      this.multipleTimeSelection = null;
      this.chooseDay =  null;
      this.currDate = null;
      this.timeToday =  null;
    },
  }
}
</script>

<style scoped lang="scss">
.date-box {
  display: flex;
}
.time-box {
  width: 500px;
  margin-left: 40px;
}
.calendar_box {
  width: 650px;
  border: 1px solid #ccc;
}
.calendar_top {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 20px;

  .ct_day {
    width: 60px;
    height: 70px;
    font-size: 22px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #232323;
    margin: 5px 10px 5px 20px;
    text-align: center;
    line-height: 70px;
  }

  .ct_years {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 22px;
    font-family: PingFang SC-Semibold, PingFang SC;
    color: #232323;
    width: 135px;

    .left_triangle {
      transform: scale(-1, 1);
      cursor: pointer;
      width: 30px;
      height: 30px;
    }

    .right_triangle {
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-left: 14px;
    }
  }
}

.calendar_center {
  user-select: none;

  .cct_list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: PingFang SC-Semibold, PingFang SC;

    .cct_item {
      width: 72px;
      margin: 0 10px;
      font-size: bold;
      color: #232323;
    }

    text-align: center;

    div {
      width: 50px;
    }
  }

  .ccb_list {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    margin-top: 5px;
    font-family: PingFang SC-Semibold, PingFang SC;
    flex-wrap: wrap;

    .ccb_item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 72px;
      height: 72px;
      cursor: pointer;
      margin: 2px 10px;
      .dot {
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #ff6f00;
      }
      .dots {
        color: #999;
      }

      .new_c {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        font-size: 16px;
        line-height: 50px;
        color: #232323;
      }

      .lunar_c {
        margin: 2px 0 1px;
        font-size: 10px;
      }
      &:nth-child(7n-6),
      &:nth-child(7n+1),
      &:nth-child(7n) {
        .new_c {
          color: #ccc;
        }
      }
    }
    .reducible {
      .new_c {
        border: 1px solid #ff6f00;
        color: #ff6f00;
      }
    }
    .nreducible {
      .new_c {
        border: 0;
        background-color: #f2f2f2;
        color: #999;
      }
    }
    .active {
      // padding-bottom: 2px;
      .new_c {
        background: #ff6f00;
        color: #FFFFFF;
      }
    }
    // .dot {
    //   width: 4px;
    //   height: 4px;
    //   background: #fff;
    //   border-radius: 50%;
    // }


    .no_belong {
      color: #d3dbe7;
      cursor: default;
    }
  }
}
::v-deep .current-row > td.el-table__cell {
  background-color: #ff6f00 !important;
  color: #fff;
}
</style>