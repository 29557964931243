<template>
  <div>
    <el-dialog center :visible.sync="dialogVisible" title="选择数据" @close="handleClose" :close-on-press-escape="false" :close-on-click-modal="false" width="50%">
      <div style="display:flex;align-item:center;margin-bottom:20px">
        <el-input clearable placeholder="请搜索关键词" style="width:300px;margin-right:20px;" size="mini" v-model="formData.keyWord">
        </el-input>
        <el-button type="primary" size="mini" @click="search">搜索</el-button>
        <el-button type="success" size="mini" @click="reset()">重置</el-button>
      </div>

      <div>
        <div class="si_area">
          <div>已选择： <el-button icon="el-icon-check" size="mini" v-if="multipleSelection.length>0">（ {{ multipleSelection.length }} ）</el-button>
            <el-button type="danger" @click="clearAll" size="mini">清除所有</el-button>
          </div>
          <el-button size="mini" v-if="formData.type == 'crmKhInfo'" @click="openAddUserDialog">添加客户</el-button>
        </div>
        <div slot="tip" class="el-upload__tip" v-html="tipMsg"></div>
        <div class="select_list"> <el-tag closable @close="removeItem(item)" class="elt_item" v-for="(item,index) in multipleSelection" :key="index">{{item[showItem]}}</el-tag>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>

      </div>

      <el-table height="500px" :data="tableData" @row-click="clickSelected">
        <el-table-column fixed="left" :show-overflow-tooltip="true" label="是否选中">
          <template slot-scope="scope">
            <div style="width:100%;height:100%">

              <img v-if="isShowFlag(scope.row)" class="check_btn nosee" src="https://oss.71go.com/bd231102/clechkecked.png" alt="">
              <img class="check_btn nosee" v-else src="https://oss.71go.com/bd231102/clechkeck.png" />
            </div>
          </template>
        </el-table-column>

        <el-table-column v-for="(item,index) in columnList" width="200px" :label="item.value" :prop="item.key" :key="index"></el-table-column>

      </el-table>
      <el-pagination :current-page.sync="formData.current" @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40]" :page-size="formData.size" :total="total"
        layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSelect">取消</el-button>
        <el-button type="primary" @click="handleConfirms">确定</el-button>
      </span>
      <!-- 多数据选择 -->
      <el-dialog center :append-to-body='true' :visible.sync="insetDialogVisible" title="由于数据存在多个可选项，请确认具体值" width="30%" :close-on-click-modal="false" @close="handleCloseDialog">
        <div style="text-align:center" v-for="(group, index) in groups" :key="index">
          <h3>{{filterData(group.id)}}</h3>
          <el-radio-group v-model="groups[index].selectedOption">
            <el-radio v-for="(option, idx) in group.options" :label="option.label" :key="idx">{{ option.label }}</el-radio>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="insetDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmSelection">确定</el-button>
        </span>
      </el-dialog>
      <el-dialog :append-to-body='true' :visible.sync="addUserDialogVisible" title="添加用户信息" width="50%" :close-on-click-modal="false">
        <el-form :model="userForm" :rules="userRules" ref="userFormData" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人姓名" prop="name" required>
                <el-input v-model="userForm.name" placeholder="请输入联系人姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户编号">
                <el-input v-model="userForm.khbm" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="性别" prop="chengwei" required>
                <el-radio-group v-model="userForm.chengwei">
                  <el-radio label="男">男</el-radio>
                  <el-radio label="女">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否主联系人" prop="sfzlxr" required>
                <el-radio-group v-model="userForm.sfzlxr">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="手机号" prop="shouji">
                <el-input v-model="userForm.shouji" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="海外电话">
                <el-input v-model="userForm.hwdh" placeholder="请输入海外电话"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="座机">
                <el-input v-model="userForm.dianhua" placeholder="请输入座机号"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="userForm.email" placeholder="请输入邮箱"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="通讯地址">
                <el-input v-model="userForm.yjdz" type="textarea" placeholder="请输入通讯地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addUserDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitUserForm">确定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <chosedate ref="choseDate" :dateForm="dateForm" @subDate="subDate" @getList="getList" />
  </div>
</template>

<script>
import {
  getVModelData,
  analyzeData,
  saveCrmContact,
} from "@/api/system/config";
import { formatDate } from "@/utils/index";
import chosedate from "../ChoseDate/index";
export default {
  components: { chosedate },
  data() {
    return {
      insetDialogVisible: false,
      total: null,
      dialogVisible: false,
      searchText: "",
      tableData: [],
      selectedData: null,
      tipMsg: null,
      formData: {
        type: null,
        size: 10,
        current: 1,
        keyWord: "",
        saveData: null,
        productId: null,
        dateStr: null,
        languageType: null,
      },
      isCrmKh: false,
      multipleSelection: [],
      columnList: [],
      showItem: null,
      reflection: [],
      groups: [],
      backData: null,
      addUserDialogVisible: false,
      userForm: {
        name: "",
        khbm: "123456", // 示例客户编号
        chengwei: "",
        sfzlxr: "",
        shouji: "",
        hwdh: "",
        dianhua: "",
        email: "",
        yjdz: "",
      },
      userRules: {
        name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        chengwei: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        sfzlxr: [
          { required: true, message: "请选择是否主联系人", trigger: "change" },
        ],
        shouji: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        email: [
          { required: false, message: "请输入邮箱", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" },
        ],
      },
      productId: null, //个人户预约所需产品id
      dateForm: [], //个人户预约银行日期数据
    };
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        item.khName.includes(this.searchText)
      );
    },
  },
  created() {},
  methods: {
    cancelSelect() {
      this.dialogVisible = false;
      this.formData = {};
      this.multipleSelection = [];
    },
    openAddUserDialog() {
      this.addUserDialogVisible = true;
      this.formData.current = 1;
    },
    submitUserForm() {
      this.$refs.userFormData.validate(async (valid) => {
        if (valid) {
          let res = await saveCrmContact(this.userForm);
          if (res.code == 200) {
            this.$modal.msgSuccess("创建成功");

            this.getList();
            this.addUserDialogVisible = false;
          } else {
            this.$msgError(res.data.returnInfo);
          }
        } else {
          return false;
        }
      });
    },

    filterData(num) {
      if (this.reflection.length > 0) {
        let foundObj = this.reflection.find(
          (obj) => obj.key && obj.key === num
        );
        if (foundObj.value) {
          return foundObj.value;
        }
      } else {
        return "";
      }
    },
    handleCloseDialog() {
      this.groups = [];
      this.insetDialogVisible = false;
    },
    confirmSelection() {
      this.groups.forEach((group) => {
        this.backData[group.id] = group.selectedOption;
      });

      this.$emit("select", this.backData);
      this.insetDialogVisible = false; // Close dialog after confirming selections
      this.dialogVisible = false; // Close dialog after confirming selections
      this.groups = [];
    },
    saveData(item) {
      this.multipleSelection.push(item);
    },
    isShowFlag(item) {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        const containsObject = this.multipleSelection.some(
          (obj) => obj[this.showItem] === item[this.showItem]
        );

        if (containsObject) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    removeItem(item) {
      // 在点击某一项时，移除该对象
      this.multipleSelection = this.multipleSelection.filter(
        (obj) => obj[this.showItem] !== item[this.showItem]
      );
      this.formData.selectData = null;
    },

    handleClose() {
      this.selectedData = null;
      this.formData.keyWord = "";
      this.multipleSelection = [];
      this.formData = {};
      this.dialogVisible = false;
    },
    rowClick(val) {
      this.selectedData = val;
    },
    reset() {
      this.formData.current = 1;
      this.formData.keyWord = null;
      this.formData.selectData = null;
      this.multipleSelection = [];
      this.getList();
    },
    //identifyType为类型 findex为参数，tValue为回显内容 reflection为映射关系
    async fetchData(identifyType, reflection, searchData, echoData, productId) {
      if (productId) {
        this.productId = productId;
      }
      if (this.$route.query.customerNo) {
        this.userForm.khbm = this.$route.query.customerNo;
        this.formData.khNum = this.$route.query.customerNo;
      }
      if (reflection) {
        this.reflection = reflection;
        // 新增是否展示字段判断 （showField）
        this.columnList = reflection.filter(
          (obj) => obj.searchType !== true && obj.showField
        );
        reflection.forEach((obj) => {
          if (obj.showType === true) {
            this.showItem = obj.key; // 假设对象只有一个属性，获取第一个属性名
          }
        });
      }
      this.formData.type = identifyType;

      if (searchData) {
        for (const key in searchData) {
          if (Object.prototype.hasOwnProperty.call(searchData, key)) {
            if (
              searchData[key] &&
              Array.isArray(searchData[key]) &&
              searchData[key].length > 0
            ) {
              this.formData[key] = searchData[key];
            } else {
              this.formData[key] = searchData[key];
            }
          }
        }
      }
      if (echoData) {
        this.formData.selectData = echoData;
        this.getList();
      } else {
        this.getList();
      }
    },
    search() {
      this.$nextTick(() => {
        this.formData.current = 1;
        this.getList();
      });
    },
    async getList(date) {
      if (this.formData.keyWord) {
        this.formData.keyWord = this.formData.keyWord.trim();
      }

      let originalString =
        (this.formData.selectData ? this.formData.selectData + ";" : "") +
        this.multipleSelection.map((item) => item.tmGid).join(";");
      let uniqueArray = [...new Set(originalString.split(";"))];
      let resultString = uniqueArray.join(";");
      this.formData.selectData = resultString;

      if (this.formData.type === "BANKTIME") {
        this.formData.productId = this.productId;
        this.formData.dateStr = date ? date : formatDate(new Date());
      }
      if (!this.formData.type) {
        return false;
      }

      if (
        this.formData.classNumber &&
        typeof this.formData.classNumber === "string"
      ) {
        // 将 classNumber 作为字符串放入数组
        const classNumberArray = [this.formData.classNumber]; // 创建数组并赋值
        this.formData.classNumber = classNumberArray; // 将数组赋值回 classNumber
      }
      let res = await getVModelData(this.formData);

      this.total = res.data.total;

      if (this.formData.type === "BANKTIME") {
        this.dateForm = res.data.data;
      } else {
        this.tableData = res.data.records;
        this.tipMsg = res.data.tipMsg;
        if (res.data.dataList && res.data.dataList.length > 0) {
          this.multipleSelection = res.data.dataList || [];
          this.formData.selectData = res.data.dataList
            .map((item) => item.tmGid)
            .join(";");
        }

        this.dialogVisible = true;
      }
    },
    // 个人户预约时间确认
    subDate(date) {
      this.$emit("select", { bankTimeStr: date });
    },
    async handleConfirms() {
      if (this.formData.type.includes("TRADEMARK")) {
        // 校验小类选择逻辑
        if (this.multipleSelection.length < 10) {
          this.$confirm("您选择的小类不足十个, 是否继续?", "提示", {
            confirmButtonText: "确认选择",
            cancelButtonText: "继续添加",
            type: "warning",
          })
            .then(() => {
              this.handleConfirm();
            })
            .catch(() => {});
        }
        // else if(this.multipleSelection.length > 10) {
        //   this.$alert('您选择的小类超过本次办理数量，请不要选择超过十个小类！', '提示', {
        //     confirmButtonText: '确定',
        //     callback: action => {}
        //   });
        // }
        else {
          this.handleConfirm();
        }
      } else {
        this.handleConfirm();
      }
    },
    async handleConfirm() {
      let data = {};
      data.data = JSON.stringify(this.multipleSelection);
      data.reflection = this.reflection;
      data.recordId = this.$route.query.recordId || "";
      // 处理确定按钮点击事件（返回选中的数据给父组件）
      let res = await analyzeData(this.formData.type, data);
      if (res.code == 200) {
        let flag = false;
        for (const key in res.data) {
          if (Array.isArray(res.data[key])) {
            this.backData = res.data;
            this.groups.push({
              id: key,
              options: res.data[key].map((item) => ({
                label: item,
                value: item,
              })),
              selectedOption: res.data[key][0],
            });
            flag = true;
          }
        }
        if (!flag) {
          this.formData.current = 1;
          this.$emit("select", res.data);
          this.dialogVisible = false;
          this.formData = {};
          this.multipleSelection = [];
        } else {
          this.insetDialogVisible = true;
        }
      }
    },
    handleSizeChange(val) {
      this.formData.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.current = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    clickSelected(row) {
      if (this.isShowFlag(row)) {
        this.removeItem(row);
      } else {
        this.saveData(row);
      }
    },
    clearAll() {
      this.formData.current = 1;
      this.formData.selectData = null;
      this.formData.keyWord = null;
      this.multipleSelection = [];
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.check_btn {
  width: 24px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.select_list {
  display: flex;
  margin: 10px;
  flex-wrap: wrap;
  i {
    width: 150px;
  }
  .elt_item {
    margin: 5px 10px;
    min-width: 150px;
    text-align: center;
    overflow: hidden;
    .el-icon-close {
      border-radius: 50%;
      text-align: center;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 20px !important;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }
  }
}
.si_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .el-table__body tr:hover {
  cursor: pointer;
}
</style>
