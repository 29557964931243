var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "left-board" },
        [
          _c("div", { staticClass: "logo-wrapper" }, [
            _c("div", { staticClass: "logo" }, [
              _c("img", { attrs: { src: _vm.logo, alt: "logo" } }),
              _vm._v(" Form Generator "),
            ]),
          ]),
          _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              _vm._l(_vm.leftComponents, function (item, listIndex) {
                return _c(
                  "div",
                  { key: listIndex },
                  [
                    _c(
                      "div",
                      { staticClass: "components-title" },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "component" },
                        }),
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.typeChange },
                        model: {
                          value: _vm.queryParams1.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams1, "type", $$v)
                          },
                          expression: "queryParams1.type",
                        },
                      },
                      _vm._l(_vm.options1, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    item.isSer
                      ? _c(
                          "div",
                          {
                            staticClass: "serach-box",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between",
                              margin: "10px 0",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                flex: "1",
                                "margin-right": "10px",
                              },
                              attrs: {
                                placeholder: "请输入模块名称",
                                clearable: "",
                              },
                              on: { input: _vm.changeInput },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleQuery1($event)
                                },
                              },
                              model: {
                                value: _vm.queryParams1.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams1, "keyword", $$v)
                                },
                                expression: "queryParams1.keyword",
                              },
                            }),
                            _c("el-button", {
                              staticStyle: {
                                width: "26px",
                                height: "30px",
                                padding: "7px",
                              },
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "mini",
                              },
                              on: { click: _vm.handleQuery1 },
                            }),
                            _c("el-button", {
                              staticStyle: {
                                width: "26px",
                                height: "30px",
                                padding: "7px",
                              },
                              attrs: { icon: "el-icon-refresh", size: "mini" },
                              on: { click: _vm.resetQuery1 },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "draggable",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingModule,
                            expression: "loadingModule",
                          },
                        ],
                        staticClass:
                          "components-draggable components-draggables",
                        attrs: {
                          clone: _vm.cloneComponent,
                          group: {
                            name: "componentsGroup",
                            pull: "clone",
                            put: false,
                          },
                          list: item.list,
                          sort: false,
                          draggable: ".components-item",
                        },
                        on: { end: _vm.onEnd },
                      },
                      _vm._l(item.list, function (element, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "components-item",
                            on: {
                              click: function ($event) {
                                return _vm.addComponent(element)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "components-body" }, [
                              _c(
                                "div",
                                { staticClass: "components-box" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": element.__config__.tagIcon,
                                    },
                                  }),
                                  _c("div", {
                                    staticStyle: { flex: "1" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getModule(
                                          element.__config__.componentName ||
                                            element.__config__.labelName
                                        )
                                      ),
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-view",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showModules(element)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isSerach &&
                              _vm.getField(element.__config__.children)
                                .length &&
                              _vm.queryParams1.keyword &&
                              (_vm.queryParams1.type === 1 ? false : true)
                                ? _c("div", { staticClass: "field-box" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "line-height": "22px" },
                                      },
                                      [_vm._v("包含字段：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "field-lis" },
                                      [
                                        _vm._l(
                                          _vm.getField(
                                            element.__config__.children,
                                            element.__config__.isMore
                                          ),
                                          function (item1, index1) {
                                            return _c("div", {
                                              key: index1,
                                              domProps: {
                                                innerHTML: _vm._s(item1),
                                              },
                                            })
                                          }
                                        ),
                                        _vm.getField(
                                          element.__config__.children
                                        ).length > 3
                                          ? [
                                              !element.__config__.isMore
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#409eff",
                                                        padding: "8px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.showMore(
                                                            element
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v("更多"),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-down",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              element.__config__.isMore
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#409eff",
                                                        padding: "8px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.showMore(
                                                            element
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v("收起"),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-up",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center-board" },
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-view", type: "text" },
                  on: { click: _vm.runView },
                },
                [_vm._v(" 预览 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-document-add", type: "text" },
                  on: { click: _vm.refForm },
                },
                [_vm._v(" 引用表单 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-edit", type: "text" },
                  on: { click: _vm.setRules },
                },
                [_vm._v(" 设置业务规则 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-edit", type: "text" },
                  on: { click: _vm.setFields },
                },
                [_vm._v(" 设置信息组合 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-edit", type: "text" },
                  on: { click: _vm.setTitles },
                },
                [_vm._v(" 设置标题 ")]
              ),
            ],
            1
          ),
          _c(
            "el-scrollbar",
            { staticClass: "center-scrollbar" },
            [
              _c(
                "el-row",
                {
                  staticClass: "center-board-row",
                  attrs: { gutter: _vm.formConf.gutter },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: _vm.formConf.size,
                        "label-position": _vm.formConf.labelPosition,
                        disabled: _vm.formConf.disabled,
                        "label-width": _vm.formConf.labelWidth + "px",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "drawing-board",
                          attrs: {
                            list: _vm.drawingList,
                            animation: 340,
                            group: "componentsGroup",
                          },
                        },
                        _vm._l(_vm.drawingList, function (item, index) {
                          return _c("draggable-item", {
                            key: item.renderKey,
                            attrs: {
                              "drawing-list": _vm.drawingList,
                              "current-item": item,
                              index: index,
                              "active-id": _vm.activeId,
                              "form-conf": _vm.formConf,
                            },
                            on: {
                              activeItem: _vm.activeFormItem,
                              copyItem: _vm.drawingItemCopy,
                              updateItem: _vm.drawingItemUpdate,
                              deleteItem: _vm.drawingItemDelete,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.drawingList.length,
                              expression: "!drawingList.length",
                            },
                          ],
                          staticClass: "empty-info",
                        },
                        [_vm._v(" 从左侧拖入或点选组件进行表单设计 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("right-panel", {
        attrs: {
          "active-data": _vm.activeData,
          "form-conf": _vm.formConf,
          drawingList: _vm.drawingList,
          fieldComList: _vm.fieldComList,
          "show-field": !!_vm.drawingList.length,
          areaGroupId: _vm.areaGroupId,
        },
        on: { "tag-change": _vm.tagChange, "fetch-data": _vm.fetchData },
      }),
      _c("form-drawer", {
        attrs: {
          visible: _vm.drawerVisible,
          "form-data": _vm.formData,
          size: "100%",
          "generate-conf": _vm.generateConf,
        },
        on: {
          "update:visible": function ($event) {
            _vm.drawerVisible = $event
          },
        },
      }),
      _c("json-drawer", {
        attrs: {
          size: "60%",
          visible: _vm.jsonDrawerVisible,
          "json-str": JSON.stringify(_vm.formData),
        },
        on: {
          "update:visible": function ($event) {
            _vm.jsonDrawerVisible = $event
          },
          refresh: _vm.refreshJson,
        },
      }),
      _c("code-type-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          title: "选择生成类型",
          "show-file-name": _vm.showFileName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          confirm: _vm.generate,
        },
      }),
      _c("input", { attrs: { id: "copyNode", type: "hidden" } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formTitle,
            visible: _vm.formOpen,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "表单名称", prop: "formName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入表单名称" },
                    model: {
                      value: _vm.form.formName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "formName", $$v)
                      },
                      expression: "form.formName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { top: "16%" },
          attrs: {
            title: "项目标题配置",
            visible: _vm.titleOpen,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.titleOpen = $event
            },
          },
        },
        [
          _c("div", { staticClass: "t-box" }, [
            _c(
              "div",
              [
                _vm._v("项目标题等于："),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.addCon()
                      },
                    },
                  },
                  [_vm._v("添加")]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      list: _vm.titleList,
                      animation: 340,
                      group: "selectItem",
                      handle: ".option-drag",
                    },
                  },
                  _vm._l(_vm.titleList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "select-item",
                        staticStyle: {
                          width: "500px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-around",
                          margin: "6px 0",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "select-line-icon option-drag" },
                          [
                            _c("i", {
                              staticClass: "el-icon-s-operation",
                              staticStyle: {
                                "font-size": "20px",
                                cursor: "move",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择模块",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.moduleChange($event, index)
                              },
                            },
                            model: {
                              value: item.moduleId,
                              callback: function ($$v) {
                                _vm.$set(item, "moduleId", $$v)
                              },
                              expression: "item.moduleId",
                            },
                          },
                          _vm._l(_vm.formListsOld, function (item) {
                            return _c("el-option", {
                              key: item.moduleId,
                              attrs: {
                                label: item.componentName,
                                value: item.moduleId,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择字段",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.fieldChange($event, index)
                              },
                            },
                            model: {
                              value: item.__vModel__,
                              callback: function ($$v) {
                                _vm.$set(item, "__vModel__", $$v)
                              },
                              expression: "item.__vModel__",
                            },
                          },
                          _vm._l(item.fieldList, function (item) {
                            return _c("el-option", {
                              key: item.__vModel__,
                              attrs: {
                                label: item.labelName,
                                value: item.__vModel__,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "close-btn select-line-icon",
                            on: {
                              click: function ($event) {
                                return _vm.titleList.splice(index, 1)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-remove-outline",
                              staticStyle: {
                                "font-size": "20px",
                                color: "#f56c6c",
                                cursor: "pointer",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setTitle } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.titleOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择需要同步至当前模块的字段",
            visible: _vm.formListOpen,
            width: "800px",
            "append-to-body": "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formListOpen = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: { data: _vm.formList },
              on: { "selection-change": _vm.formListChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "字段名称", align: "center", prop: "formName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.__config__.label) + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFormSele },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.seleCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "引用历史项目表单",
            visible: _vm.projectOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.projectOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                size: "small",
                inline: true,
                "label-width": "68px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "项目编号", prop: "projectCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入项目编号", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.projectCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "projectCode", $$v)
                      },
                      expression: "queryParams.projectCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目名称", prop: "projectName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入项目名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "projectName", $$v)
                      },
                      expression: "queryParams.projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "pro-table",
              attrs: { data: _vm.projectList, "highlight-current-row": "" },
              on: { "current-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { width: "55", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.id },
                            model: {
                              value: _vm.radioSelected,
                              callback: function ($$v) {
                                _vm.radioSelected = $$v
                              },
                              expression: "radioSelected",
                            },
                          },
                          [_vm._v(_vm._s(""))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "项目编号",
                  prop: "projectCode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "项目名称",
                  prop: "projectName",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "领域名称", prop: "areaName" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "领域小组名称",
                  prop: "areaGroupName",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "项目备注", prop: "remark" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "项目版本", prop: "version" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "禁用状态", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("dict-tag", {
                          attrs: {
                            options: _vm.dict.type.sys_normal_disable,
                            value: row.status,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "创建者", prop: "createBy" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "更新者", prop: "updateBy" },
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.queryParams.current,
              limit: _vm.queryParams.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitPro } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancelPro } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "表单预览",
            visible: _vm.viewOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.viewOpen = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-boxs" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "form-tabs",
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.handleTabClick },
                  model: {
                    value: _vm.fromTab,
                    callback: function ($$v) {
                      _vm.fromTab = $$v
                    },
                    expression: "fromTab",
                  },
                },
                _vm._l(_vm.formConf.fields, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: {
                      label: item.__config__.labelName,
                      name: item.__config__.moduleId,
                    },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "form-right" },
                [
                  _vm.isRequestCompleted
                    ? _c("parser", {
                        key: _vm.parserKey,
                        ref: "parser",
                        attrs: {
                          "form-conf": _vm.formConf,
                          recordStatus: 0,
                          previewShow: true,
                        },
                        on: { getData: _vm.getData },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("set-rules", {
        ref: "setRules",
        attrs: { formLists: _vm.formLists, rulesDatas: _vm.rulesDatas },
        on: { conRules: _vm.conRules },
      }),
      _c("set-fields", {
        ref: "setFields",
        attrs: { formLists: _vm.formLists, fieldsDatas: _vm.fieldsDatas },
        on: { conFields: _vm.conFields },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "formOpenBox",
          attrs: {
            title: "查看模块表单",
            visible: _vm.moduleFormOpen,
            width: "80%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleFormOpen = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "module-box" },
            [
              !_vm.isloading
                ? _c("build-index", {
                    ref: "build",
                    attrs: {
                      showlr: true,
                      moduleLayout: _vm.moduleForm.__config__.allowMultiple
                        ? "tsSubform"
                        : "rowFormItem",
                      formcont: _vm.moduleFormcont,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitBuild } },
                [_vm._v("添加至表单")]
              ),
              _c("el-button", { on: { click: _vm.formOpenCanlc } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }