<template>
  <div>
    <el-dialog class="set-tag" title="设置个人标签" :visible.sync="addDialog" append-to-body width="800px">
      <div class="add-tag">
        <div class="tips">注：设置标签仅供个人查看</div>
        <div class="set-content" v-if="!isEditTag">
          设置标签：<el-input v-model="tagInput" placeholder="请输入内容" v-trim></el-input><el-button size="mini" type="primary" @click="addTag">保存</el-button>
        </div>
        <div class="tag-box" v-if="dynamicTags.length || dynamicTagss.length">
          <div class="left">已创建标签：</div>
          <div class="tag-list" v-if="!isEditTag">
            <el-tag
              :key="tag.id"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="deleteTag(tag,true)">
              {{tag.tagValue}}
            </el-tag>
          </div>
          <div class="tag-list" v-else>
            <el-tag
              :key="tag.id"
              v-for="tag in dynamicTagss"
              closable
              :disable-transitions="false"
              @close="deleteTag(tag,false)">
              {{tag.tagValue}}
            </el-tag>
          </div>
        </div>
        <div class="tip-content">
          删除已创建标签，对应设置了该标签的项目将会同步取消标签设置 <span>且无法恢复</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="我的自定义标签项目" :visible.sync="showDialog" append-to-body width="1200px">
      <div class="tag-list-box" v-if="showDialog">
        <div class="left">
          <el-tree :data="tagList" :props="defaultProps" :default-expand-all="true" @node-click="handleNodeClick">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <div @click.stop="handleNodeTextClick(data)">{{ node.label }}<span v-if="data.total">({{data.total}})</span></div>
            </span>
          </el-tree>
          <el-button type="primary" plain icon="el-icon-edit" size="small" @click="editTag">编辑标签</el-button>
        </div>
        <div class="right-box">
          <search-list @handleSearch="handleSearch" :headData="headData" :headFixData="headFixData" :searchList="searchData" :searchData="otherData" @saveSearchHead="saveSearchHead"></search-list>
          <el-table @sort-change="handleSortChange" border height="580" fit v-loading="loading" ref="table" :data="listData" stripe :header-cell-style="{ background: '#f2f3f5', color: '#7a7e83' }">
            <custom-table-column v-for="item in filteredHeadFixData" :key="item.id" :item="item" :dict="dict.type[item.dictType]" />

            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="text" 
                  v-hasPermi="[
                    'deliver:entire:detial',
                    'deliver:draft:detial',
                    'deliver:processing:detial',
                    'deliver:completed:detial',
                    'deliver:abnormal:detial',
                    'deliver:cancel:detial',
                    'deliver:lock:detial',
                    'deliver:send:detial',
                    'deliver:reject:detial'
                  ]" 
                  icon="el-icon-view" 
                  @click="goTasksend(scope.row.recordId)">项目详情</el-button>
              </template>
            </el-table-column>
          </el-table>

          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.current" :limit.sync="queryParams.size" @pagination="currentChange" />

        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listRecordTagValue, sysRecordTagAdd, deleteRecordTagValue } from "@/api/flowable/recordtag";
import searchList from "@/components/SearchList";
import CustomTableColumn from "@/components/CustomTableColumn";
import mixinSearch from "@/utils/mixinSearch";
export default {
  name: "RecordTag",
  mixins: [mixinSearch],
  components: {
    searchList,
    CustomTableColumn,
  },
  dicts: [
    "bpm_record_status",
    "bpm_record_task_status",
    "bpm_record_task_type",
    "order_status",
    "order_prod_status",
    "order_refund_status",
    "bpm_record_task_operation_type",
  ],
  props: {
    dynamicTags: {
      type: Array,
      default: () => []
    },
    recordId: {
      type: String,
      default: null
    }
  },
  watch: {
    showDialog: {
      async handler(val) {
        if (val) {
          
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      addDialog:false,
      tagInput:'',
      isEditTag: false,
      dynamicTagss:[],
      showDialog:false,
      tagList:[],
      defaultProps: {
        children: 'children',
        label: 'tagValue'
      },
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        current: 1,
        size: 10,
        vw_table: "vw_deliver_list_tag_record",
      },
    }
  },
  methods: {
    // 点击标签
    handleNodeClick(data) {},
    handleNodeTextClick(data) {
      if(!data.tagValue) return
      this.queryParams.current = 1;
      if(data.tagValue == "全部标签") {
        this.queryParams.tagValue = null;
      } else {
        this.queryParams.tagValue = data.tagValue;
      }
      this.getList();
    },
    goTasksend(id) {
      let url = this.$router.resolve({
        path: "/manage/tasksend",
        query: {
          recordId: id,
          isOpened: true,
        },
      }).href;
      window.open(url, "_blank");
    },
    currentChange() {
      this.getList();
    },
    editTag() {
      this.isEditTag = true;
      this.addDialog = true;
    },
    // 打开标签弹窗
    openAddTag() {
      this.tagInput = "";
      this.isEditTag = false;
      this.addDialog = true;
    },
    // 打开标签列表弹窗
    async openShowDialog() {
      this.queryParams.tagValue = null;
      await this.getTagList()
      this.showDialog = true
    },
    async getTagList() {
      const {code,data,msg} = await listRecordTagValue({}).then()
      if(code == 200) {
        this.dynamicTagss = data;
        let params = {
          tagValue: "全部标签",
          children: data
        }
        this.$set(this.tagList,0,params)
        console.log(this.tagList);
        this.getList();
        this.$forceUpdate()
      } else {
        this.$message.error(msg);
      }
    },
    addTag() {
      if(!this.tagInput) {
        this.$message({
          message: '请先输入标签内容',
          type: 'error'
        })
        return
      }
      let params = [{
        type: 1,
        recordId: this.recordId,
        tagLabel: this.tagInput,
        tagValue: this.tagInput,
        createMode: 1
      }]
      sysRecordTagAdd(params).then(res => {
        if(res.code == 200) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.addDialog = false
          this.$emit("updateTag",'add',params)
        }
      })
    },
    deleteTag(tag,type) {
      this.$confirm('是否确认删除该标签?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        deleteRecordTagValue({tagValue:tag.tagValue,tagId:tag.id}).then(res => {
          if(res.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            if(type){
              this.$emit("updateTag",'delete',tag)
            } else {
              this.getTagList()
            }
          }
        })
      }).catch(() => {
        
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.add-tag {
  .tips {
    margin: 10px 0;
    color: red;
  }
  .set-content {
    display: flex;
    align-items: center;
    .el-input {
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
  .tag-box {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }
  .tip-content {
    color: #333;
    margin-top: 16px;
    span {
      color: red;
    }
  }
  .tag-list {
    .el-tag {
      margin: 0 4px 4px 0;
    }
  }
}
.tag-list-box {
  display: flex;
  .left {
    .el-button {
      margin: 6px auto;
      display: block;
    }
  }
  ::v-deep .el-tree {
    width: 260px;
    min-height: 700px;
    .el-tree-node__content {
      height: unset;
      margin: 4px 0;
    }
    .custom-tree-node {
      width: 100%;
      white-space: pre-wrap;
    }
  }
  .right-box {
    width: 900px;
  }
}
</style>