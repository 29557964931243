var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-table-column", {
    key: _vm.item.id,
    attrs: {
      sortable: "custom",
      label: _vm.item.headName,
      align: "center",
      prop: _vm.item.headField,
      "min-width": _vm.minWidth,
      "show-overflow-tooltip": true,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (scope) {
          return [
            _vm.item.htmlType === "input"
              ? _c("div", [
                  _vm.item.headField === "tagLabel"
                    ? _c(
                        "div",
                        { staticClass: "tb_item" },
                        _vm._l(
                          _vm.removeOuterQuotes(scope.row[_vm.item.headField]),
                          function (tag, tagIndex) {
                            return _c(
                              "el-tag",
                              {
                                key: tagIndex,
                                staticClass: "el_tag",
                                attrs: { size: "mini" },
                              },
                              [_vm._v(" " + _vm._s(tag) + " ")]
                            )
                          }
                        ),
                        1
                      )
                    : _vm.item.headField === "recordCode"
                    ? _c("div", { staticClass: "tb_item" }, [
                        _c(
                          "div",
                          { staticClass: "copy_recordcode" },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: scope.row[_vm.item.headField],
                                  expression: "scope.row[item.headField]",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.clipboardSuccess,
                                  expression: "clipboardSuccess",
                                  arg: "success",
                                },
                              ],
                              staticClass: "el-icon-document-copy",
                            }),
                            _c(
                              "router-link",
                              {
                                key: "expand",
                                staticClass: "sidebar-logo-link",
                                attrs: {
                                  to:
                                    "/manage/tasksend?recordId=" +
                                    scope.row.recordId,
                                },
                              },
                              [
                                _c("div", { staticClass: "go_recordcode" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row[_vm.item.headField]) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _c("div", { staticClass: "wrap_con" }, [
                        _vm._v(" " + _vm._s(scope.row[_vm.item.headField])),
                      ]),
                ])
              : _vm.item.htmlType === "select"
              ? _c("div", [
                  _vm.item.headField === "memberType" &&
                  scope.row[_vm.item.headField]
                    ? _c("div", [
                        _c("img", {
                          staticClass: "img_width",
                          attrs: {
                            src:
                              "https://oss.71go.com/bpm/" +
                              scope.row[_vm.item.headField] +
                              ".png",
                            alt: "",
                          },
                        }),
                      ])
                    : _c(
                        "div",
                        [
                          _c("dict-tag", {
                            attrs: {
                              options: _vm.dict,
                              value: scope.row[_vm.item.headField],
                            },
                          }),
                        ],
                        1
                      ),
                ])
              : _vm.item.htmlType === "datetime"
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parseTime(
                          scope.row[_vm.item.headField],
                          "{y}-{m}-{d} {h}:{i}:{s}"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }