import request from '@/utils/request'

// 查询所有自定义创建标签数据列表
export function listRecordTagValue(data) {
  return request({
    url: '/system/sysRecordTag/listRecordTagValue',
    method: 'get',
    params: data
  })
}

// 新增标签数据
export function sysRecordTagAdd(data) {
  return request({
    url: '/system/sysRecordTag',
    method: 'post',
    data: data
  })
}

// 删除标签数据
export function deleteRecordTagValue(data) {
  return request({
    url: '/system/sysRecordTag/deleteRecordTagValue',
    method: 'delete',
    params: data
  })
}