<template>
  <el-table-column sortable="custom" :label="item.headName" align="center" :prop="item.headField" :key="item.id" :min-width="minWidth" :show-overflow-tooltip="true">
    <template slot-scope="scope">

      <div v-if="item.htmlType === 'input'">
        <div v-if="item.headField === 'tagLabel'" class="tb_item">
          <el-tag size="mini" class="el_tag" v-for="(tag, tagIndex) in removeOuterQuotes(scope.row[item.headField])" :key="tagIndex">
            {{ tag }}
          </el-tag>
        </div>
        <div v-else-if="item.headField === 'recordCode'" class="tb_item">

          <div class="copy_recordcode"><i class="el-icon-document-copy" v-clipboard:copy="scope.row[item.headField]" v-clipboard:success="clipboardSuccess"></i>
            <router-link key="expand" class="sidebar-logo-link" :to="'/manage/tasksend?recordId='+scope.row.recordId">
              <div class="go_recordcode">
                {{scope.row[item.headField]}}
              </div>
            </router-link>

          </div>

        </div>
        <div class="wrap_con" v-else> {{scope.row[item.headField] }}</div>
      </div>
      <div v-else-if="item.htmlType === 'select'">
        <div v-if="item.headField === 'memberType' && scope.row[item.headField]">
          <img class="img_width" :src="'https://oss.71go.com/bpm/'+scope.row[item.headField]+'.png'" alt="">
        </div>
        <div v-else>
          <dict-tag :options="dict" :value="scope.row[item.headField]" />
        </div>
      </div>

      <div v-else-if="item.htmlType === 'datetime'">
        {{ parseTime(scope.row[item.headField], '{y}-{m}-{d} {h}:{i}:{s}') }}
      </div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "CustomTableColumn",
  props: {
    item: {
      type: Object,
      required: true,
    },
    dict: {
      type: Array, // 修改为数组
      default: () => [],
    },
  },
  computed: {
    isSortable() {
      return true;
    },
    minWidth() {
      if (this.item.headField === "recordCode") {
        return 270;
      }
      switch (this.item.htmlType) {
        case "datetime":
          return 200;
        case "number":
          return 180; // 示例：数字类型的最小宽度
        case "select":
          return 160; // 示例：下拉框类型的最小宽度
        default:
          return 170; // 默认值
      }
    },
  },
  methods: {
    clipboardSuccess() {
      this.$modal.msgSuccess("复制成功");
    },
    removeOuterQuotes(value) {
      // 判断 value 是否包含逗号
      if (value) {
        if (value.includes(",")) {
          return value.split(","); // 根据逗号分割为数组
        } else {
          return [value]; // 将 value 放入数组中返回
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tb_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  .el-tag {
    margin-bottom: 3px;
  }
}
.img_width {
  width: 80px;
}
.copy_recordcode {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    width: 40px;
  }
  .go_recordcode {
    border: #46a6ff;
    color: #46a6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 200px;
  }
}
.wrap_con {
  word-break: break-all; /* 强制在任意字符处换行 */
  white-space: pre-wrap;
  line-height: 16px;
  // max-height: 40px;
}
</style>
